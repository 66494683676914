<template>
  <div class="FreeCourse">
    <img src="../../assets/img/PolicyLibrary_04.png" alt="" />
    <!-- <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item>
      </van-swipe>
    </div>
    <div class="FreeCourse_main">
      <div class="course" v-for="item in 4" :key="item">
        <div class="course_left">
          <van-image
            width="160px"
            fit="cover"
            lazy-load
            :src="require('../../assets/image/FreeCourse.png')"
          />
        </div>
        <div class="course_right">
          <h4 class="van-multi-ellipsis--l3">
            关于劳动合同的接触和终止，所有的细节你掌握了吗
          </h4>
          <div class="browse">
            <van-icon name="eye-o" />
            20000
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Swipe, SwipeItem, Image as VanImage, Lazyload, Icon } from "vant";
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Lazyload.name]: Lazyload,
    [Icon.name]: Icon,
  },
};
</script>
<style lang="scss" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.FreeCourse_main {
  width: 100%;
  padding: 10px;
  .course {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    .course_left {
      padding-right: 10px;
    }
    .course_right {
      h4 {
        font-weight: bold;
        font-size: 17px;
        padding-bottom: 10px;
      }
      .browse {
        text-align: end;
      }
    }
  }
}
</style>
